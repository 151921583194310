@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    display: flex;
    width: 100vw;
    height: 100vh;
    @apply font-sans
}
